import ProjectService from "@/services/project.service";
import CheckmailService from "@/services/checkmail.service";

const initialState = {
    project: {},
    projectList: [],
}
export const project = {
    namespaced: true,
    state: initialState,
    actions: {
        decrementAccess({commit}, projectId) {
            commit('decrementAccess', {projectId});
        },
        setProjectList({commit}) {
            return ProjectService.getProjects().then(
                async function (response) {
                    const projectList = response.data;
                    for (const project of projectList) {
                        try {
                            const access = await CheckmailService.getAccess(project.token);
                            project.remain = access.data.remain;
                        } catch (error) {
                            project.remain = null;
                        }
                    }
                    commit('setProjectList', projectList);
                    // Initialize "default" project
                    commit('setProject', projectList.find(project => project.name === 'Default Project'));
                    return Promise.resolve(projectList);
                },
                function (error) {
                    return Promise.reject(error);
                }
            );
        },
        setProject({commit}, project) {
            commit('setProject', project);
        },
        addProject({commit}, {projectName, userUuid}) {
            return ProjectService.createProject(projectName, userUuid).then(
                function (response) {
                    commit('addToProjectList', response.data);
                    commit('setProject', response.data);
                    return Promise.resolve(response.data);
                },
                function (error) {
                    return Promise.reject(error);
                }
            );
        },
        deleteProject({commit}, projectId) {
            return ProjectService.deleteProject(projectId).then(
                function (response) {
                    commit('removeFromProjectList', projectId);
                    return Promise.resolve(response);
                },
                function (error) {
                    return Promise.reject(error);
                }
            );
        }
    },
    mutations: {
        decrementAccess(state, {projectId}) {
            const project = state.projectList.find(project => project.id === projectId);
            console.log(project);
            if (project) {
                if (project.remain !== 0) {
                    project.remain -= 1;
                }
            }
        },
        setAccess(state, access) {
            state.access = access;
        },
        setProjectList(state, projects) {
            state.projectList = projects;
        },
        addToProjectList(state, project) {
            state.projectList.push(project);
        },
        removeFromProjectList(state, projectId) {
            if (state.project.id === projectId) {
                state.project = state.projectList.find(project => project.name === 'default');
            }
            state.projectList = state.projectList.filter(project => project.id !== projectId);
        },
        setProject(state, project) {
            state.project = project;
        }
    }
}